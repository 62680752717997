import styled from "styled-components"
import { theme as themeConfig } from "UI/theme"

export const RibbonWrapper = styled.div`
  cursor: pointer;
`
export const ModalRibbon = styled.img`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
`
export const ModalIcon = styled.img`
  position: absolute;
  top: 50%;
  left: -2px;
  transform: translate(0, -50%);
`
export const RibbonCloseWrapper = styled.div`
  width: 36px;
  height: 28px;
  position: absolute;
  cursor: pointer;
`
export const ModalCloseRibbon = styled.img`
  position: absolute;
  top: 0;
  left: 0;
`
export const ModalCloseIcon = styled.img`
  position: absolute;
  top: 50%;
  left: 23px;
  transform: translate(-100%, -50%);
`

export const RibbonTitle = styled.button`
  border-radius: 2px;
  border: 1px solid rgba(218, 210, 210, 0.2);
  padding: 8px 25px 8px 30px;
  margin-left: -15px;
  color: ${themeConfig.palette.text.primary};
  font-family: "Oswald";
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.01em;
  text-align: left;
  text-transform: uppercase;
  background-color: transparent;
  z-index: 1;
  position: relative;
  cursor: pointer;

  &:hover {
    background-color: rgba(223, 74, 50,0.7);
    color: black;
  }

  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
    letter-spacing: 1.6px;
  }
`

export const RibbonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  cursor: pointer;

  svg:hover + ${RibbonTitle} {
    background-color: rgba(223, 74, 50,0.7);
    color: black;
  }

  @media (max-width: 768px) {
    margin-bottom: 40px;
    width: 100%;
  }
`
