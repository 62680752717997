import React, { useContext } from "react"
import { Popup } from "../../../interfaces/Page"
import { RibbonContainer, RibbonTitle } from "./styles"
import { ModalContext } from "../../store/ModalContext"

type Props = {
  title: string
  popup: Popup
}

const SvgRibbon: React.FC<Props> = ({ title, popup }): JSX.Element => {
  const modalCtx = useContext(ModalContext)

  return (
    <RibbonContainer
      onClick={() => {
        modalCtx.setModal(popup)
      }}
    >
      <svg
        // onClick={() => { /* modalCtx.setModal(null) */ }}
        style={{ zIndex: 2 }}
        width="36"
        height="28"
        version="1.1"
        viewBox="0 0 36 28"
      >
        <g>
          <g>
            <path
              d="M1.28319,28h32.05998c1.12,0 2.03,-0.91 2.03,-2.03v-23.94c0,-1.12 -0.91,-2.03 -2.03,-2.03h-32.05998c-0.42,0 -0.63,0.42 -0.49,0.77l4.9,13.09c0.07,0.14 0.07,0.28 0,0.42l-4.9,13.09c-0.14,0.21 0.07,0.63 0.49,0.63z"
              fill="#df4a32"
              fillOpacity="1"
            ></path>
          </g>
          <g>
            <path
              d="M18.06152,8.75366v0l5.24634,5.24634v0l-5.24634,5.24634v0"
              fillOpacity="0"
              fill="#ffffff"
              strokeDashoffset="0"
              strokeLinejoin="round"
              strokeLinecap="round"
              strokeOpacity="1"
              stroke="#23191c"
              strokeMiterlimit="20"
              strokeWidth="2"
            ></path>
          </g>
        </g>
      </svg>
      <RibbonTitle>{title}</RibbonTitle>
    </RibbonContainer>
  )
}

export default SvgRibbon
